import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import React from "react";
import { Logout } from "@mui/icons-material";
import { authFirebase } from "../Conections/Firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const NavbarProject = () => {
  const navigation = useNavigate();

  const cerrarSesion = async () => {
    try {
      await signOut(authFirebase);
      localStorage.setItem("user", "");
      navigation("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error.message);
    }
  };
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Inversiones
        </Typography>
        <IconButton
          onClick={() => {
            cerrarSesion();
          }}
          size="large"
          edge="start"
          color="inherit"
          aria-label="Cerrar sesion"
          sx={{ mr: 2 }}
        >
          <Logout />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default NavbarProject;
