import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getUser } from '../Utils/Storage';


const ProtectedRoutes = ({ redirectTo = '/login', rol }) => {
  const userLogin = getUser();
  if (userLogin && rol == userLogin.rol) {
    return <Outlet />;
  }
  return <Navigate to={redirectTo}></Navigate>;
};

export default ProtectedRoutes;
