import {
  Box,
  Button,
  Grid,
  ListItemIcon,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import { authFirebase } from "../../Conections/Firebase";
import { setUser } from "../../Utils/Storage";
import { useNavigate } from "react-router-dom";

import IMG_LOGO from "../../imgs/img-logo-nueva.jpeg";
import Swal from "sweetalert2";

const LoginAuth = () => {
  const navigation = useNavigate();

  const validationSchema = yup.object({
    email: yup
      .string("Ingresa el correo")
      .email("Ingresa un correo valido")
      .required("El correo es requerido"),
    password: yup
      .string("Ingresa una contraseña")
      .min(8, "La contraseña debe tener 8 o más caracteres")
      .required("La contraseña es requerida"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const userCredential = await signInWithEmailAndPassword(
          authFirebase,
          values.email,
          values.password
        );
        let userLogin = {
          auth: userCredential._tokenResponse,
          rol: "Administrador",
        };
        setUser(userLogin);
        setTimeout(() => {
          navigation("/inversion");
        }, 1000);
      } catch (error) {
        console.error("Error logging in:", error);
        Swal.fire({
          title: "Accesos incorrectos",
          text: "El correo/contraseña son incorrectos",
          icon: "error",
          confirmButtonText: "Intentar de nuevo",
        });
      }
    },
  });

  const objPersona = [
    {
      id: "email",
      type: "email",
      label: "Correo electronico",
      value: formik.values.email,
      touched: formik.touched.email,
      errors: formik.errors.email,
      xs: 12,
      md: 12,
      lg: 12,
    },
    {
      id: "password",
      type: "password",
      label: "Contraseña:",
      value: formik.values.password,
      touched: formik.touched.password,
      errors: formik.errors.password,
      xs: 12,
      md: 12,
      lg: 12,
    },
  ];
  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} md={4} sx={{margin:'20px',border:'1px solid rgb(205, 205, 192)',borderRadius:'5px',padding:'15px',background:'rgb(205, 205, 192,0.2)'}}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Grid
              container
              direction={"row"}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Stack alignItems="center" justifyContent="center" spacing={1} sx={{margin:'20px'}}>
                  <Typography
                    gutterBottom
                    variant={"h4"}
                    sx={{ textAlign: "center",color:'rgb(0, 122, 255)'}}
                  >
                    Bienvenido a AppInvest
                  </Typography>
                </Stack>

                <Box sx={{textAlign:'center'}}>
                  <img alt="..." src={IMG_LOGO} width={200} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="center"
              >
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1">
                    Ingresa tu credenciales para continuar
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <form onSubmit={formik.handleSubmit} spacing={2}>
              <Grid container>
                {objPersona.map((input) => (
                  <Grid
                    key={input.id}
                    item
                    xs={input.xs}
                    md={input.md}
                    lg={input.lg}
                    sx={{ marginTop: "15px" }}
                  >
                    <TextField
                      fullWidth
                      id={input.id}
                      type={input.type}
                      name={input.id}
                      label={input.label}
                      value={input.value}
                      onChange={formik.handleChange}
                      error={input.touched && Boolean(input.errors)}
                      helperText={input.touched && input.errors}
                      focused={input.focused}
                    />
                  </Grid>
                ))}
              </Grid>

              <Button
                color="primary"
                variant="contained"
                style={{ marginTop: "10px" }}
                startIcon={<ListItemIcon />}
                type="submit"
                fullWidth
              >
                Entrar
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginAuth;
