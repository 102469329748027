//Formato de fecha para cuando los datos regresan de la db
export const formateoFechas = (fecha) => {
  const date = new Date(fecha);
  // Obtener los componentes de la fecha
  const day = date.getDate();
  const month = date.toLocaleString("es-ES", { month: "long" });
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Formatear la fecha
  return `${day} de ${month} de ${year} ${hours}:${minutes}`;
};

//Formato de fecha para los inputs
export const getCurrentDateTime = () => {
  const current = new Date();
  const year = current.getFullYear();
  const month = String(current.getMonth() + 1).padStart(2, "0"); // Mes comienza en 0
  const day = String(current.getDate()).padStart(2, "0");
  const hours = String(current.getHours()).padStart(2, "0");
  const minutes = String(current.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const getUser = () => {
  let user = localStorage.getItem('user');
  if (user) return JSON.parse(window.atob(user));
  else return false;
};

export const setUser = (user) => {
  let userLogin = {
    auth: user.auth,
    rol: user.rol
  };

  localStorage.setItem('user', window.btoa(JSON.stringify(userLogin)));
};