// project imports
import React from 'react';
import { Route, Routes } from 'react-router';
import MinimalLayout from './MinimalLayout';
import ProtectedRoutes from './ProtectedRoutes';
import Inversiones from '../Components/Inversiones';
import RouteRedirect from './RouteRedirect';
import LoginAuth from '../Components/Auth/LoginAuth';

const MainRoutes = () => {
  
  
  return (
    <Routes>
      <Route index element={<MinimalLayout />}></Route>
      <Route path="/login" element={<LoginAuth />}></Route>


      <Route element={<ProtectedRoutes rol={'Administrador'} />}>
        <Route path="/inversion" element={<Inversiones />}></Route>
       
      </Route>

      {/* Ruta para redirigir cuando la ruta no existe */}
      <Route path="*" element={<RouteRedirect />} />
    </Routes>
  );
};

export default MainRoutes;
