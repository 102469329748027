import React, { useEffect, useState } from "react";
import { addDoc, collection, getDocs, getFirestore } from "firebase/firestore";
import { appFirebase, authFirebase } from "../Conections/Firebase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { formateoFechas, getCurrentDateTime } from "../Utils/Storage";
import { useTheme } from "@mui/material/styles";
import Swal from "sweetalert2";

import NavbarProject from "./NavbarProject";
import TableEdit from "./TableEdit";

const Inversiones = () => {
  const columns = [
    { key: "raiz", header: "inversión" },
    { key: "tipo", header: "Tipo inversión" },
    { key: "cantidad", header: "Cantidad" },
    { key: "formattedDate", header: "Fecha de registro" },
  ];

  const [inversiones, setInversiones] = useState([]);
  const [listInver, setListInver] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [objInversion, setObjInversion] = useState({
    fecha: getCurrentDateTime(),
    cantidad: 0,
    raiz: "",
    tipo: "",
  });

  const db = getFirestore(appFirebase);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const getInversiones = async () => {
    const querySnapshot = await getDocs(collection(db, "inversiones"));

    const investData = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      const formattedDate = formateoFechas(data.fecha);
      return {
        id: doc.id,
        ...data,
        formattedDate,
        cantidad: "$" + data.cantidad,
        raiz:
          listInver.length > 0
            ? listInver.find((f) => f.id === data.raiz).nombre
            : "S/N",
      };
    });
    setInversiones(investData);
  };

  const getListaInversiones = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "lista_inversion"));

      const investData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
        };
      });

      setListInver(investData);
    } catch (e) {
      Swal.fire({
        title: "Inversión erronea",
        text: "La inversion fallo con el siguiente error: " + e,
        icon: "error",
        confirmButtonText: "Aceptar",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  const addInvest = async () => {
    try {
      const newInversion = objInversion;
      const docRef = await addDoc(collection(db, "inversiones"), newInversion);
      Swal.fire({
        title: "Inversión agregada con éxito",
        text: "La inversion fue agregada con el ID: " + docRef.id,
        icon: "success",
        confirmButtonText: "Aceptar",
      }).then(() => {
        window.location.reload();
      });
    } catch (e) {
      Swal.fire({
        title: "Inversión erronea",
        text: "La inversion fallo con el siguiente error: " + e,
        icon: "error",
        confirmButtonText: "Aceptar",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  useEffect(() => {
    getListaInversiones();
  }, []);

  useEffect(() => {
    if (listInver.length > 0) {
      getInversiones();
    }
  }, [listInver]);

  return (
    <>
      <NavbarProject></NavbarProject>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ padding: "15px" }}>
          <Button
            onClick={() => {
              setOpenModal(true);
            }}
            variant="contained"
            color="success"
            sx={{ marginBottom: "15px" }}
          >
            Agregar inversión
          </Button>
          <TableEdit columns={columns} rows={inversiones}></TableEdit>
        </Grid>

        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth={"xs"}
          open={openModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Agregar cambios en las inversiones"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container>
                <Grid item md={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Inversion
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={objInversion.raiz}
                      label="Inversion"
                      onChange={(event) => {
                        setObjInversion((prevState) => ({
                          ...prevState,
                          raiz: event.target.value,
                        }));
                      }}
                    >
                      {listInver.map((row) => (
                        <MenuItem key={row.id} value={row.id}>
                          {row.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth sx={{ marginTop: "15px" }}>
                    <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={objInversion.tipo}
                      label="Inversion"
                      onChange={(event) => {
                        setObjInversion((prevState) => ({
                          ...prevState,
                          tipo: event.target.value,
                        }));
                      }}
                    >
                      <MenuItem value={"INGRESO"}>INGRESO</MenuItem>
                      <MenuItem value={"EGRESO"}>EGRESO</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    sx={{ marginTop: "15px" }}
                    id="outlined-basic"
                    label="Cantidad"
                    variant="outlined"
                    type="number"
                    onChange={(evt) => {
                      setObjInversion((prevState) => ({
                        ...prevState,
                        cantidad: evt.target.value,
                      }));
                    }}
                  />

                  <TextField
                    fullWidth
                    sx={{ marginTop: "15px" }}
                    id="outlined-basic"
                    InputLabelProps={{ shrink: true }}
                    label="Fecha"
                    variant="outlined"
                    type="datetime-local"
                    value={objInversion.fecha}
                    onChange={(evt) => {
                      setObjInversion((prevState) => ({
                        ...prevState,
                        fecha: evt.target.value,
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cerrar
            </Button>
            <Button
              onClick={() => {
                setOpenModal(false);
                addInvest();
              }}
              autoFocus
            >
              Agregar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default Inversiones;
