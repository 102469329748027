import { Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

const RouteRedirect = () => {
  const navigationPage = useNavigate();

  setTimeout(() => {
    navigationPage('/login');
  }, 1000);
  return (
    <>
      <Typography>Redireccionando...</Typography>
    </>
  );
};

export default RouteRedirect;
