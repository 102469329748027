import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";


const MinimalLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};

export default MinimalLayout;
